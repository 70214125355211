<div class="modal-content">
    <div class="modal-header">
      
    </div>
    <div class="modal-body">
        <h5 class="mb-3 text-center" *ngIf="esCuentaPrueba">Su periodo de prueba ha vencido.</h5>
        <h5 class="mb-3 text-center" *ngIf="!esCuentaPrueba">Su suscripción ha vencido.</h5>
        <h6 class="mb-5 text-center" *ngIf="esCuentaPrueba">Comuníquese con su agente de ventas para contratar un plan.</h6>
        <h6 class="mb-5 text-center" *ngIf="!esCuentaPrueba">Comuníquese con su agente de ventas para regularizar su situación o {{!enableSubscriptionUI ? 'realice el pago a través del siguiente enlace y envíenos el comprobante.' : esCuentaPrueba ? 'suscríbase desde el siguiente enlace.' : 'regularice su suscripción.'}} </h6>
<!-- 
        <h6 class="mb-5 text-center" *ngIf="!esCuentaPrueba && plan == 1 && tipo == 3"><a href="https://www.flow.cl/btn.php?token=ae4qejv">Pagar Aquí</a></h6>
        <h6 class="mb-5 text-center" *ngIf="!esCuentaPrueba && plan == 1 && tipo == 4"><a href="https://www.flow.cl/btn.php?token=o3mpiwr">Pagar Aquí</a></h6>
        <h6 class="mb-5 text-center" *ngIf="!esCuentaPrueba && plan == 2 && tipo == 3"><a href="https://www.flow.cl/btn.php?token=efpb5kr">Pagar Aquí</a></h6>
        <h6 class="mb-5 text-center" *ngIf="!esCuentaPrueba && plan == 2 && tipo == 4"><a href="https://www.flow.cl/btn.php?token=zwditdx">Pagar Aquí</a></h6>
        <h6 class="mb-5 text-center" *ngIf="!esCuentaPrueba && plan == 3 && tipo == 3"><a href="https://www.flow.cl/btn.php?token=y4ysmui">Pagar Aquí</a></h6>
        <h6 class="mb-5 text-center" *ngIf="!esCuentaPrueba && plan == 3 && tipo == 4"><a href="https://www.flow.cl/btn.php?token=kbfwegp">Pagar Aquí</a></h6> -->

        <h6 class="mb-3 text-center" *ngIf="esCuentaPrueba"><div
                class="d-flex gap-2 align-items-center justify-content-center"><a class="btn btn-success"
                    target="_blank" href="https://api.whatsapp.com/send?phone=56944758039"><i
                        class="fa-brands fa-whatsapp"></i></a> <a href="tel:56944758039">+56 9 4475 8039</a></div>
        </h6>
        <h6 class="mb-5 text-center" *ngIf="esCuentaPrueba"><a
                href="mailto:natalia@kuvemar.com">natalia@kuvemar.com</a></h6>

        <h6 class="mb-3 text-center" *ngIf="!esCuentaPrueba"><div
                class="d-flex gap-2 align-items-center justify-content-center"><a class="btn btn-success"
                    target="_blank" href="https://api.whatsapp.com/send?phone=56975155189"><i
                        class="fa-brands fa-whatsapp"></i></a> <a href="tel:56975155189">+56 9 4475 8039</a></div>
        </h6>
        <h6 class="mb-5 text-center" *ngIf="!esCuentaPrueba"><a
                href="mailto:ventas@kuvemar.com">ventas@kuvemar.com</a></h6>



        <!-- <h5 class="mb-5 text-center">O renueve su suscripción manualmente.</h5>
        <button class="btn btn-primary btn-lg w-100 text-center" (click)="renovarPlan()"><i class="fas fa-rocket"></i> Renovar Plan</button> -->
        <!-- <div (click)="openLink()" style="cursor: pointer;" class="d-flex justify-content-center align-items-center w-100 flex-column">
            <img style="max-width: 300px;" src="assets/system/K-PAGOS_NEGRO.png" alt="Logo KPagos">
            <h5 class="btn-link text-primary">Visitar KPagos</h5>
        </div> -->

        
        <!-- <div class="w-100 d-flex justify-content-center my-3" *ngIf="enableSubscriptionUI">
            <div class="w-100 btn btn-primary text-center" (click)="pagarPlan()">{{esCuentaPrueba || !enableSubscriptionUI ? 'Pasar a Premium' : 'Actualizar Suscripción'}}</div>
        </div> -->
        <div class="w-100 d-flex justify-content-center my-2">
            <div class="w-100 btn btn-outline-secondary text-center" (click)="cerrarSesion()">Cerrar Sesión</div>
        </div>
    </div>
    <div class="modal-footer">
    </div>
</div>