export const environment = {
  production: true,
  envName: 'production',
  title: 'Production Environment Heading',
  webURL: 'https://lubritaller.kuvesoft.com/',
  apiURL: 'https://lubritaller.kuvesoft.com:4008/',
  kPagosURL: 'https://kpagos.kuvesoft.xyz/',
  habilitarSuscripciones: false,
  version: '1.6.0'
};
